import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  ASSOCIATED,
  CUSTOMER,
  NATIVE,
  PREVIEW,
  PROMOTIONAL,
  SUCCESS,
  TEXTURE,
  THUMBNAIL, TURNTABLE,
  VIEWER
} from '../components/products/constants'
import {
  filterStatus,
  filterType,
  filterErrored,
  count,
  mapIdAsKey,
  arrayFrom,
  filterThumbnails, objectFrom, filterNotTurntableCandidate, checkThumbnailType
} from '../utils/ProductFiles'
import { debounce } from 'lodash'
import useDeleteFiles from './useDeleteFiles'
import usePatchFile from './usePatchFile'

const reduceFile = (acc, upload) => {
  const { type, attributes: { fileId, isNative, name, size, imageWidth, imageHeight, validSearchBackground } } = upload
  const thumbnailAttributes = type === THUMBNAIL
    ? {
        thumbnailType: checkThumbnailType(name, imageWidth, imageHeight),
        sourceWidth: imageWidth,
        sourceHeight: imageHeight,
        validSearchBackground,
      }
    : {}
  return {
    ...acc,
    [fileId.toString()]: {
      type: type,
      id: fileId.toString(),
      attributes: {
        isNative,
        name,
        size,
        ...thumbnailAttributes
      },
    }
  }
}

export default function useFiles({
  initialFiles,
  initialPreviews,
  allUploads,
  ackUploads,
  csrfToken,
  productId,
  draftId
}) {
  const [allFiles, setFiles] = useState({
    ...mapIdAsKey(initialFiles),
    ...mapIdAsKey(initialPreviews.map((p, i) => ({
      ...p,
      index: i
    })))
  })

  useEffect(() => {
    debouncedConsumeUploads(allUploads)
  }, [allUploads])

  const debouncedConsumeUploads = useCallback(
    debounce(uploads => consumeUploads(uploads)), []
  )

  const consumeUploads = (uploads) => {
    const consumableUploads = filterNotTurntableCandidate(uploads)
    const completedUploads = filterStatus(consumableUploads, SUCCESS)

    if (count(completedUploads)) {
      setFiles(prevFiles => ({
        ...prevFiles,
        ...arrayFrom(completedUploads).reduce(reduceFile, {})
      }))
      ackUploads(arrayFrom(completedUploads).map(u => u.id))
    }
  }

  const clearFile = (id) => {
    setFiles(prevFiles => {
      const { [id]: value, ...rest } = prevFiles
      return rest
    })
  }

  const clearFiles = (files) => {
    setFiles(prevFiles => {
      const ids = files.map(f => f.id)
      return Object.keys(prevFiles).reduce((acc, key) => {
        if (ids.includes(key)) {
          return acc
        }
        else {
          return { ...acc, [key]: prevFiles[key] }
        }
      }, {})
    })
  }

  const updateFiles = (files) => {
    setFiles(prevFiles => {
      return {
        ...prevFiles,
        ...objectFrom(files),
      }
    })
  }

  const {
    deletedFiles,
    deleteFiles,
    pendingDelete,
    undoDeleteFiles,
    pendingUndoDelete
  } = useDeleteFiles({
    csrfToken,
    draftId,
    productId,
    updateFiles,
    clearFile
  })

  const {
    isPatching,
    patchFile
  } = usePatchFile({
    csrfToken,
    draftId,
    productId
  })

  const files = useMemo(() => ({
    [NATIVE]: filterType(allFiles, NATIVE),
    [PREVIEW]: filterThumbnails(allFiles, [
      THUMBNAIL,
      TURNTABLE
    ]),
    [ASSOCIATED]: filterType(allFiles, [
      TEXTURE,
      PROMOTIONAL,
      CUSTOMER,
      VIEWER,
    ])
  }), [allFiles])

  const errors = useMemo(() => ({
    [NATIVE]: filterErrored(files.native),
    [PREVIEW]: filterErrored(files.preview),
    [ASSOCIATED]: filterErrored(files.associated),
  }), [files])

  return {
    files,
    errors,
    clearFile,
    clearFiles,
    updateFiles,
    deletedFiles,
    deleteFiles,
    pendingDelete,
    isPatching,
    patchFile,
    undoDeleteFiles,
    pendingUndoDelete,
    csrfToken,
  }
}
