import useReorder from './useReorder'
import { arrayFrom, filterPendingTurntables, previewFiles } from '../utils/ProductFiles'
import { useEffect, useRef } from 'react'
import * as ThumbnailService from '../services/thumbnail-service'
import { useMutation } from 'react-query'
import { ASC } from '../components/products/constants'
import { usePrevious } from './usePrevious'
import { isEqual } from 'lodash'
import { useIsReady } from './useIsReady'

export function usePreviewsOrder({
  allPreviews,
  updateFiles,
  draftId,
  csrfToken,
  overridePreviewRequirements,
}) {
  const { searchPreview, previews } = previewFiles(allPreviews, overridePreviewRequirements)
  const orderedPreviews = arrayFrom(previews)
    .sort((a, b) => a.index - b.index)
  const { sortableEl, order } = useReorder()
  const prevSearchPreviewId = usePrevious(searchPreview?.id)
  const prevOrderedPreviewsIds = usePrevious(orderedPreviews.map(p => p.id))
  const prevOrder = usePrevious(order)
  const isReady = useIsReady()
  const pendingTurntables = filterPendingTurntables(previews)

  const {
    mutate: saveOrder,
    isLoading
  } = useMutation(
    'saveOrder',
    ({ reorderedPreviews }) => ThumbnailService.orderPreviews({
      files: reorderedPreviews,
      draftId,
      csrfToken
    })
  )

  // React to search preview becoming available or being removed
  useEffect(() => {
    const searchPreviewChanged = prevSearchPreviewId !== searchPreview?.id
    const orderedPreviewsChanged = !isEqual(prevOrderedPreviewsIds, orderedPreviews.map(p => p.id))
    const shouldUpdate =
      isReady
      && (searchPreviewChanged || orderedPreviewsChanged)
      && !isLoading
      && !pendingTurntables.length

    if (shouldUpdate) {
      const reorderedPreviews = [
        ...(searchPreview ? [searchPreview] : []),
        ...orderedPreviews
      ].map(({ id }, index) => ({ ...allPreviews[id], id, index }))
      updateFiles(reorderedPreviews)
      saveOrder({ reorderedPreviews })
    }
  }, [searchPreview, orderedPreviews])

  // React to drag and drop ordering
  useEffect(() => {
    const shouldUpdate = order
      && !isEqual(prevOrder, order)
      && !isLoading
    if (shouldUpdate) {
      const reorderedPreviews = [
        ...(searchPreview ? [searchPreview] : []),
        ...order.map((id, index) => ({ ...allPreviews[id], id, index }))
      ]
      updateFiles(reorderedPreviews)
      saveOrder({ reorderedPreviews })
    }
  }, [searchPreview, order])

  const sortAlpha = (direction) => {
    const alphaOrderedPreviews = arrayFrom(previews).sort((a, b) => {
      const textA = a.attributes.name.toUpperCase();
      const textB = b.attributes.name.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })
    const reorderedPreviews = direction === ASC
      ? alphaOrderedPreviews
      : alphaOrderedPreviews.reverse()
    const indexedPreviews = [
      ...(searchPreview ? [searchPreview]: []),
      ...reorderedPreviews
    ].map(({ id }, index) => ({ ...allPreviews[id], id, index }))
    updateFiles(indexedPreviews)
    saveOrder({ reorderedPreviews: indexedPreviews })
  }

  return {
    sortableEl,
    orderedPreviews,
    searchPreview,
    order,
    sortAlpha
  }
}
