import React from 'react'
import PropTypes from 'prop-types'

export default class EditableCell extends React.Component {
  constructor(props) {
    super(props)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.handleStartEdit = this.handleStartEdit.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)

    this.txtInput = React.createRef()
    this.state = {
      displayValue: props.value,
      isHovering: false,
      isEditing: false,
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    this.setState({ displayValue: nextProps.value })
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      document.removeEventListener('mousedown', this.handleClickOutside)
      this.setState({ isEditing: false, isHovering: false })
    }
  }

  handleMouseEnter() {
    const { isEditing } = this.state
    const { turbosquidId, pendingProductIds } = this.props
    if (!isEditing && !pendingProductIds.includes(turbosquidId)) {
      this.setState({ isHovering: true })
    }
  }

  handleMouseLeave() {
    const { isEditing } = this.state
    if (!isEditing) {
      this.setState({ isHovering: false })
    }
  }

  handleStartEdit() {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.setState({ isEditing: true })
  }

  handleKeyPress(event) {
    if (event.keyCode === 13) {
      this.handleSubmit(event)
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    const { handleEditCellSubmit, name, editValue: oldValue, turbosquidId } = this.props
    const newValue = this.txtInput.current.value.replace(/[[\]{}<>()_]/g, '')
    if (newValue !== oldValue) {
      const data = { name, value: newValue, turbosquid_id: turbosquidId }
      handleEditCellSubmit(data)
    }

    this.setState({ displayValue: newValue, isEditing: false, isHovering: false })
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  render() {
    const { displayValue } = this.state
    const {
      name,
      editValue,
      turbosquidId,
      tagName: CustomTag,
      className,
      columnSize,
      maxLength,
      restrictWidth,
    } = this.props
    const { isEditing, isHovering } = this.state
    const displayHtml = restrictWidth ? <span>{ displayValue }</span> : displayValue
    const additionalAttributes = {}
    if (maxLength > 0) {
      additionalAttributes.maxLength = maxLength
    }

    return (
      <div
        ref={this.setWrapperRef}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        { isEditing
        && (
          <span>
            <input
              ref={this.txtInput}
              name={name}
              defaultValue={editValue}
              size={columnSize}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...additionalAttributes}
              onKeyDown={this.handleKeyPress}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <i
              className="fa fa-check"
              style={{ marginLeft: '5px', color: '#ff8135' }}
              role="button"
              tabIndex={0}
              onClick={this.handleSubmit}
              onKeyDown={this.handleSubmit}
            />
          </span>
        )}
        { !isEditing
        && (
          <CustomTag key={turbosquidId} className={`editableCell ${className}`}>
            { displayHtml }
            { isHovering
            && (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <i
                className="fa fa-pencil"
                style={{ marginLeft: '5px', opacity: 0.5 }}
                role="button"
                tabIndex={0}
                onClick={this.handleStartEdit}
                onKeyDown={this.handleSubmit}
              />
            )}
          </CustomTag>
        )}
      </div>
    )
  }
}

EditableCell.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editValue: PropTypes.string.isRequired,
  turbosquidId: PropTypes.string.isRequired,
  pendingProductIds: PropTypes.array.isRequired,
  tagName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  columnSize: PropTypes.number.isRequired,
  maxLength: PropTypes.number,
  handleEditCellSubmit: PropTypes.func.isRequired,
  restrictWidth: PropTypes.bool,
}

EditableCell.defaultProps = {
  restrictWidth: false,
  maxLength: 0,
}
