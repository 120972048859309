import {
  ASSOCIATED,
  STEMCELL_CERTIFICATION,
  filesI18nOptions,
  MARMOSET,
  MIN_PREVIEW_HEIGHT, MIN_UV_HEIGHT, MIN_UV_WIDTH,
  MIN_WIREFRAME_HEIGHT,
  MIN_WIREFRAME_WIDTH,
  NATIVE,
  NATIVE_COUNT,
  NONE,
  OTHER,
  PREVIEW_COUNT,
  PREVIEWS,
  RAR,
  REGULAR,
  SEVENZ,
  THUMBNAIL,
  TURNTABLE_COUNT,
  UV,
  VIEWER,
  WIREFRAME,
  WIREFRAME_COUNT,
  ZIP
} from '../components/products/constants'
import React from 'react'
import { recognizeExtension } from './FileFormatsHelpers'

export const filterType = (object, types) =>
  Object.keys(object)
    .filter(key => types.includes(object[key].type))
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterStatus = (object, status) =>
  Object.keys(object)
    .filter(key => object[key].status === status)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterStatusNot = (object, status) =>
  Object.keys(object)
    .filter(key => object[key].status !== status)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterTurntableCandidate = (object) =>
  Object.keys(object)
    .filter(key => !!object[key].attributes.turntableId)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterNotTurntableCandidate = (object) =>
  Object.keys(object)
    .filter(key => !object[key].attributes.turntableId)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterThumbnails = (object, types) =>
  Object.keys(object)
    .filter(key => !object[key].attributes.turntableId && types.includes(object[key].type))
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterThumbnailType = (object, types) =>
  Object.keys(object)
    .filter(key => object[key].type === THUMBNAIL && types.includes(object[key].attributes.thumbnailType))
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterErrored = (object) =>
  Object.keys(object)
    .filter(key => object[key].errors?.length)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filterMinDimensions = (object, minWidth, minHeight) =>
  Object.keys(object)
    .filter(key => object[key].attributes.sourceWidth >= minWidth && object[key].attributes.sourceHeight >= minHeight)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})

export const filter3dModels = ({ media_types: mediaTypes }) => {
  return mediaTypes.find((type) => type.id === '3d_models')
}

export const filterPrimary = (object) => (
  Object.keys(object)
    .filter(key => object[key].attributes.isNative)
    .reduce((acc, key) => ({ ...acc, [key]: object[key] }), {})
)

export const count = (object) => Object.keys(object).length

export const validate = (file, validators) => {
  return validators.flatMap(v => {
    const error = v(file)
    return error.then ? error : Promise.resolve(error)
  })
}

export const mapIdAsKey = (object) => {
  return object.reduce((acc, file) => {
    const { id, ...attributes } = file
    return { ...acc, [id]: attributes }
  }, {})
}

export const arrayFrom = (object) => Object.keys(object).map(id => ({
  id,
  ...object[id]
}))

export const objectFrom = (array) => array.reduce((acc, item) => ({
  ...acc,
  [item.id]: item,
}), {})

export const arrayMove = (array, oldIndex, newIndex) => {
  const remaining = [...array.slice(0, oldIndex), ...array.slice(oldIndex+1)]
  if (newIndex === 0) {
    return [array[oldIndex], ...remaining]
  } else if (newIndex === remaining.length) {
    return [...remaining, array[oldIndex]]
  } else {
    const newArray = [...remaining.slice(0, newIndex), array[oldIndex], ...remaining.slice(newIndex)]
    return newArray
  }
}

export const validSearchPreview = (preview) =>
  preview.type === THUMBNAIL
  && preview.attributes.thumbnailType === REGULAR
  && preview.attributes.validSearchBackground
  && preview.attributes.sourceWidth >= 1200
  && preview.attributes.sourceWidth === preview.attributes.sourceHeight

// There are cases where a search preview is not used (eg. collections, certain feature ids)
// previewOverride is passed in to determine if a search preview should be used

export const previewFiles = (allPreviews, previewOverride) => {
  const previewsArray = arrayFrom(allPreviews)
  const searchPreview = previewOverride ? undefined : previewsArray.find(validSearchPreview)
  const previews = (() => {
    if (searchPreview) {
      return previewsArray.filter(p => p.id !== searchPreview.id)
    } else {
      return previewsArray
    }
  })()
  return {
    searchPreview,
    previews,
  }
}

export const names = (object) => {
  return arrayFrom(object).reduce((acc, item) => {
    const { id, attributes: { name } } = item
    return { ...acc, [id]: name }
  }, {})
}

export const checkThumbnailType = (filename, imageWidth, imageHeight) => {
  const name = filename.toLowerCase()
  const isWireframe = name.match(/wire/)
  const isUv = name.match(/uv/)
  const isValidSize = (
    isWireframe && (imageWidth >= MIN_WIREFRAME_WIDTH && imageHeight >= MIN_WIREFRAME_HEIGHT)
  ) || (
    isUv && (imageWidth >= MIN_UV_WIDTH && imageHeight >= MIN_UV_HEIGHT)
  )
  return isValidSize ? (isWireframe ? WIREFRAME : UV) : REGULAR
}

// Format Recognition
// - marmoset viewer files are set to an OTHER format
// - archive files can use four naming patterns:
//   - [name].[extension] -> recognized as OTHER format
//   - [name]_[format].[extension] -> will try to recognize the format
//   - [name]-[format].[extension] -> will try to recognize the format
//   - [name].[format].[extension] -> will try to recognize the format
// - all other files will try to be recognized from the extension

export const recognizeFormat = (filename, formats = []) => {
  const extension = recognizeExtension(filename.toLowerCase(), formats)

  if (extension) {
    if ([MARMOSET].includes(extension)) {
      // Set marmoset viewer format
      return formats.find(f => f.id === OTHER)?.id
      // Recognize archive format or set as "other"
    } else if ([RAR, ZIP, SEVENZ].includes(extension)) {
      const name = filename.split(`.${extension}`)[0]
      const e = recognizeExtension(name.toLowerCase(), formats)
      return e !== -1 ? e : formats.find(f => f.id === OTHER)?.id
    } else {
      return  extension === -1 ? undefined : extension
    }
  } else {
    return undefined
  }
}

export const fileWithIsNative = (file, value) => {
  return {
    ...file,
    attributes: {
      ...file.attributes,
      isNative: value
    }
  }
}

export const resolvePrimary = (files) => {
  if (count(files)) {
    const primary = arrayFrom(files).find(file => file.attributes.isNative)
    if (primary) {
      const { [primary.id]: _, ...nativeFiles } = files
      return [primary, nativeFiles]
    } else {
      return [undefined, files]
    }
  } else {
    return [undefined, {}]
  }
}

export const recognizePrimary = (files, formats) => {
  if (!count(files)) {
    return [undefined, {}]
  } else {
    const firstNativeFile = arrayFrom(files).find(f => {
      const formatData = (formats || []).find((format) => {
        const fileFormat = recognizeFormat(f.attributes.name, formats)
        return format.id === fileFormat
      })
      return !!formatData?.native
    })
    if (firstNativeFile) {
      const { [firstNativeFile.id]: file, ...rest } = files
      return [firstNativeFile, rest]
    } else {
      return [undefined, files]
    }
  }
}

export const removeExtension = (name) => {
  return name.substr(0, name.lastIndexOf('.')) || name
}

export const filesWithErrors = (files, errors) => {
  const fileIds = Object.keys(errors)
  const filesWithErrors = arrayFrom(files).filter(f => fileIds.includes(f.id))
  return filesWithErrors.map(({ id }) => ({
    id,
    type: files[id].type,
    attributes: {
      file: files[id],
      error: errors[id]
    }
  }))
}

export const validationErrors = (errors, key) => {
  return Object.keys(errors[key] ?? {})
}

export const fileErrors = (errors, files) => {
  return Object.keys(errors ?? {})
    .filter(id => !!files[id])
    .map(id => errors[id])
}

export const nativeErrors = (errors, files) => {
  const fErrors = fileErrors(errors, files)
  const nativeErrors = Object.keys(errors[NATIVE] ?? {})
    .filter(key => [
      NATIVE_COUNT
    ].includes(key))
    .map(id => errors[id])
  return [...fErrors, ...nativeErrors]
}

export const previewErrors = (errors) => {
  return Object.keys(errors[PREVIEWS] ?? {})
    .filter(key => [
      PREVIEW_COUNT,
      WIREFRAME_COUNT,
      TURNTABLE_COUNT
    ].includes(key))
    .map(id => errors[id])
}

export const stemcellFromCerts = (certifications) => {
  return certifications[STEMCELL_CERTIFICATION];
}

export const hasMarmosetFile = (files) => {
  return arrayFrom(files).some(f => f.type === VIEWER)
}

export const fieldSuffix = (name) => {
  const parts = name.split('.')
  return parts[parts.length-1]
}

export const nativeErrorMessage = (translation, setCurrentTab) => {
  return (
    <>
      <a onClick={() => setCurrentTab(NATIVE)} className="field-link">
        {`${I18n.t('files', filesI18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, filesI18nOptions)}</span>
    </>
  )
}

export const previewErrorMessage = (translation, setCurrentTab) => {
  return (
    <>
      <a onClick={() => setCurrentTab(PREVIEWS)} className="field-link">
        {`${I18n.t('images', filesI18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, filesI18nOptions)}</span>
    </>
  )
}

export const associatedErrorMessage = (translation, { setCurrentTab, params }) => {
  return (
    <>
      <a onClick={() => setCurrentTab(ASSOCIATED)} className="field-link">
        {`${I18n.t('textures_and_other_files', filesI18nOptions)}: `}
      </a>
      <span className="error-messages">{I18n.t(translation, {
        scope: 'turbosquid.products.product_validation',
        ...params
      })}</span>
    </>
  )
}

export const imageResolution = (file) => (
  new Promise((resolve) => {
    const fileReader = new FileReader()
    const image = new Image()
    fileReader.onload = (e) => {
      image.src = e.target.result
      image.onload = () => {
        resolve({ width: image.width, height: image.height })
      }
    }

    fileReader.readAsDataURL(file)
  })
)

export const partOfSet = (prev, next) => {
  return !next.filter(id => !prev.includes(id)).length
}

export const filterPendingTurntables = (files) => {
  return files.filter(f => f.id && !f.id.toString().match(/^[0-9]{8}$/g))
}

export const stripName = (name) => {
  return name.trim().replace(/[[\]{}<>()]/g, '')
}

export const isOverrideFeature = (overrideIds) => {
  return (feature) => {
    if (feature && overrideIds.includes(feature.id)) return true
    if (!(feature && feature.ancestry_ids)) return false

    return feature.ancestry_ids.filter(id => overrideIds.includes(id)).length > 0
  }
}
