import { mapGon } from '../services/gon-service'
import { useEffect, useState } from 'react'
import * as DraftService from '../services/draft-service'
import { useMutation } from 'react-query'
import useCertifications from './useCertifications'
import useSyncLegacyFields from './useSyncLegacyFields'
import { isOverrideFeature } from '../utils/ProductFiles'

const NULL_PRODUCT_ID = '0'

export default function useProduct(gon, form, csrfToken) {

  const {
    product,
    validations,
    endpoints,
    tips,
    collectionTaxonomyId,
    previewOverrideTaxonomyIds,
    legacyProductDate,
    checkmateEnabled,
    checkMateDisabledLink,
  } = mapGon(gon)

  const [draft, setDraft] = useState(product.draftId ? {
    id: product.draftId,
    createdAt: product.createdAt,
    updatedAt: product.updatedAt,
    name: product.name
  } : {})

  const {
    mutateAsync: createDraft,
  } = useMutation(
    'createDraft',
    ({ csrfToken }) => DraftService.createDraft(csrfToken)
  )

  const {
    mutateAsync: patchDraft,
    isLoading: isSaving,
  } = useMutation(
    'patchDraft',
    (attributes) => {
      return DraftService.updateDraft({
        ...draft,
        ...attributes
      }, csrfToken);
    }
  )

  useEffect(() => {
    if (!product.draftId) {
      createDraft({ csrfToken })
        .then(d => {
          if (d) {
            setDraft({ id: d.id })
            if (product.id === NULL_PRODUCT_ID) {
              // TODO: use history from react-router-dom v5 or find v6 solution
              window.history.replaceState({}, '', `/turbosquid/drafts/${d.id}/edit`)
            }
          }
        })
    }
  }, [])

  const saveDraft = async (attributes) => {
    if (draft) {
      if (attributes) {
        await patchDraft(attributes)
      }
      setDraft(prevDraft => ({
        ...prevDraft,
        ...attributes,
        updatedAt: new Date()
      }))
    } else {
      throw new Error('draft does not exist')
    }
  }

  const certifications = useCertifications({
    draftId: draft.id,
    product,
    csrfToken
  })

  const syncFields = useSyncLegacyFields({
    product,
    collectionTaxonomyId
  })

  const overridePreviewRequirements = (syncFields.fields.features ?? []).some(
    isOverrideFeature([
      collectionTaxonomyId,
      ...previewOverrideTaxonomyIds
    ])
  )

  return {
    product,
    productId: product.id,
    draftId: draft.id ?? product.draftId,
    draft,
    saveDraft,
    isSaving,
    validations,
    endpoints,
    tips,
    csrfToken,
    certifications,
    collectionTaxonomyId,
    previewOverrideTaxonomyIds,
    overridePreviewRequirements,
    legacyProductDate,
    syncFields,
    checkmateEnabled,
    checkMateDisabledLink,
  }
}
