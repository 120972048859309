/* gon */

import { del, get, patch, post } from './service'
import { mapCertIdToApi, mapPreview, mapProduct } from './gon-service'

export const createDraft = (csrfToken) => {
  const productId = gon.product.id
  const url = `/turbosquid/products/${productId}/create_draft`
  return post(url, { authenticity_token: csrfToken })
}

export const updateDraft = (draft, csrfToken) => {
  const productId = gon.product.id
  const url = `/turbosquid/products/${productId}`
  return patch(url, {
    authenticity_token: csrfToken,
    turbosquid_product_form: {
      draft_id: draft.id.toString(),
      ...draft
    }
  }).then(({ product }) => {
    return mapProduct(product)
  })
}

export const getPreviews = async ({ draftId }) => {
  const previews = await get(`/turbosquid/drafts/${draftId}/previews?id=${draftId}`)
  return previews.map(p => mapPreview({
    id: p.id,
    type: p.type,
    ...p.attributes
  }))
}

export const addCertification = async ({ certification, draftId, csrfToken }) => {
  const url = `/turbosquid/products/${draftId}/certifications`
  return post(url, {
    id: mapCertIdToApi("stemcell_v2"),
    draft_id: draftId.toString(),
    authenticity_token: csrfToken
  })
}

export const removeCertification = async ({ certification, draftId, csrfToken }) => {
  const certEndpoint = mapCertIdToApi(certification)
  const url = `/turbosquid/products/${draftId}/certifications/${certEndpoint}`
  return del(url, {
    draft_id: draftId.toString(),
    authenticity_token: csrfToken
  })
}
