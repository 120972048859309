import { useState } from 'react'
import { NATIVE } from '../components/products/constants'

export default function useUI() {
  const [currentTab, setCurrentTab] = useState(NATIVE)

  return {
    currentTab,
    setCurrentTab
  }
}
