/* global I18n, gon */
import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line import/no-extraneous-dependencies
import queryString from 'query-string'
import Decimal from 'decimal.js'

// eslint-disable-next-line import/no-extraneous-dependencies
import SelectableReactTable from '../../components/products/SelectableReactTable'
import { partnerColumns } from '../../components/products/CustomTableCells/columnsMyProducts'
import Paginator from '../../shared/Paginator'
import { encodeQuery, buildUrlParams, cookie } from '../../components/products/helpers'
import ProductsService from '../../services/ProductsService'
import SearchForm from '../../components/products/SearchHeader/SearchForm'
import AlertBox from '../../shared/AlertBox'
import PriceUpdateForm from '../../components/products/PriceModal/PriceUpdateForm'
import StatusUpdateForm from '../../components/products/StatusModal/StatusUpdateForm'
import DownloadModal from '../../components/products/DownloadModal/DownloadModal'
import RemovalForm from '../../components/products/RemovalModal/RemovalForm'
import GenericModal from '../../shared/GenericModal'
import Sidebar from '../../shared/Sidebar'
import SidebarContent from '../../components/products/SidebarContent/SidebarContent'
import FilterModalContent from '../../components/products/FilterModal/FilterModalContent'
import FilterAdditionalData from '../../components/products/SearchHeader/FilterAdditionalData'
import FilterBulkActions from '../../components/products/SearchHeader/FilterBulkActions'
import FiltersMyProducts from '../../components/products/SearchHeader/FiltersMyProducts'
import ViewDrafts from '../../components/products/SearchHeader/ViewDrafts'
import CreateNewProduct from '../../components/products/SearchHeader/CreateNewProduct'
import { PENDING_ASSETS_POLL_INTERVAL, BETA_PUBLISHER_SWITCH_COOKIE_NAME } from '../../components/products/constants'
import { post, get, patch, del } from '../../services/service'
import { ThumbnailCell, CheckmateCell, StatusCell, FeatureCell, IdCell, DateCell, PriceCell, ActionCell, StemcellCell } from '../../components/products/CustomTableCells/index'
import PendingAssetsService from '../../services/PendingAssetsService'
import ToggleAccess from '../../components/products/SearchHeader/ToggleAccess'
import { draftEditPageUrl, editDraftUrl } from '../../components/drafts/helpers'
import SpinnerOverlay from '../../products/react_components/SpinnerOverlay'
import collectionLoadingTransparent from '../../../../assets/images/Collection_Loading_transparent.gif'
import CreateCollection from '../../components/products/SearchHeader/CreateCollection'
import { createCollectionAssets } from '../../services/CreateCollectionAssetsService'

const quickFiltersBaseUrl = '/turbosquid/quick_filters/'

const errorFetch = () => I18n.t('turbosquid_products.filter_titles.network_error')

const defaultSorted = (search) => {
  if (search.sort) {
    const sortData = search.sort.split(',')
    let desc = false
    if (sortData[1] && sortData[1] === 'desc') {
      desc = true
    }
    return [{ id: sortData[0], desc }]
  }
  return []
}
class MyProducts extends React.Component {
  constructor(props) {
    super(props)

    const searchObject = queryString.parse(props.location.search)
    const queryPage = parseInt(searchObject.page || 1, 10)
    const queryPageSize = parseInt(searchObject.size || gon.default_page_size, 10)
    const syndicationPartners = { p5: 'POND5', f3d: 'FREE3D' }
    this.renderEditablePrice = this.renderEditablePrice.bind(this)
    this.renderEditableName = this.renderEditableName.bind(this)
    this.renderIdCell = this.renderIdCell.bind(this)
    this.renderFeatureCell = this.renderFeatureCell.bind(this)
    this.renderDateModifiedCell = this.renderDateModifiedCell.bind(this)

    const selectedSyndicationPartners = {}
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in syndicationPartners) {
      selectedSyndicationPartners[key] = { value: syndicationPartners[key], selected: false }
    }

    const baseOptions = {
      style: {
        flexDirection: 'row-reverse',
      },
      headerStyle: {
        flexDirection: 'row-reverse',
      },
    }

    const defaultColumns = [
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        minWidth: 80,
        Cell: this.renderActionCell,
      },
      {
        Header: 'ID',
        maxWidth: 80,
        minWidth: 80,
        id: 'turbosquid_id',
        accessor: 'turbosquid_id',
        headerClassName: 'turbosquid_id',
        className: 'turbosquid_id',
        Cell: this.renderIdCell,
        ...baseOptions,
      },
      {
        Header: 'Name',
        id: 'product_name',
        minWidth: 280,
        Cell: this.renderEditableName,
      },
      {
        Header: 'Categories',
        accessor: 'feature_assignments',
        sortable: false,
        minWidth: 175,
        Cell: this.renderFeatureCell,
        style: { textAlign: 'left' },
      },
      {
        Header: 'Price',
        id: 'price_value',
        accessor: 'price',
        headerClassName: 'price',
        className: 'price',
        minWidth: 100,
        Cell: this.renderEditablePrice,
        ...baseOptions,
      },
      {
        Header: 'Date Added',
        id: 'classic_created_at',
        minWidth: 125,
        accessor: 'date_added',
        headerClassName: 'date_added',
        className: 'date_added',
        Cell: (row) => DateCell({ ...row.original, value: row.value, displayOptions: { classes: 'classic_created_at_cell' } }),
        ...baseOptions,
      },
      {
        Header: 'Date Modified',
        id: 'classic_updated_at',
        minWidth: 125,
        accessor: 'date_modified',
        headerClassName: 'date_modified',
        className: 'date_modified',
        Cell: this.renderDateModifiedCell,
        ...baseOptions,
      },
      {
        Header: 'Sales',
        accessor: 'sale_count',
        headerClassName: 'sale_count',
        className: 'sale_count',
        maxWidth: 70,
        minWidth: 70,
        Cell: ({ value }) => <span className="sale_count_cell">{ value }</span>,
        ...baseOptions,
      },
      {
        Header: 'Total Sales',
        accessor: 'sale_total',
        headerClassName: 'sale_total',
        className: 'sale_total',
        minWidth: 105,
        Cell: ({ value }) => <span className="sale_total_cell">{ value }</span>,
        ...baseOptions,
      },
      {
        Header: 'StemCell',
        accessor: 'stemcell_status',
        headerClassName: 'stemcell_status',
        className: 'stemcell_status',
        id: 'status',
        minWidth: 110,
        Cell: (row) => StemcellCell(row.original),
      },
      {
        Header: 'Status',
        accessor: 'status',
        headerClassName: 'status',
        className: 'status',
        id: 'status',
        minWidth: 110,
        Cell: (row) => StatusCell(row.original),
      },
      {
        Header: 'CheckMate',
        minWidth: 125,
        accessor: 'checkmate_status',
        headerClassName: 'checkmate_status',
        className: 'checkmate_status',
        id: 'metadata.turbosquid.checkmate_status.pipeline_status',
        Cell: (row) => CheckmateCell(row.original),
      },
    ]

    searchObject.page = queryPage
    searchObject.size = queryPageSize
    this.state = {
      data: [],
      pendingAssets: [],
      pendingUpdateAssets: [],
      loading: true,
      pages: null,
      pageSize: queryPageSize,
      defaultPageSize: queryPageSize,
      defaultPage: queryPage,
      search: queryString.stringify(searchObject),
      page: queryPage,
      aggregations: {},
      total: 0,
      sorted: defaultSorted(searchObject),
      showpriceModal: false,
      showStatusModal: false,
      showDownloadModal: false,
      showRemovalModal: false,
      selectedSyndicationPartners,
      alert: { message: '', type: '' },
      tableColumns: defaultColumns,
      hasSyndication: false,
      toggleSidebar: true,
      showFilterModal: false,
      filterModalTitle: '',
      filtersData: [],
      selectedFilter: null,
      filtersLoading: false,
      crudError: null,
      newFilterName: null,
      selectedFolder: null,
      activeFilterId: null,
      method: null,
      id: null,
      term: this.getTermFromQuery(searchObject),
      allowDuplicate: true,
      showOverlay: false,
      allowDownload: true,
      downloadModalTitle: I18n.t('turbosquid_products.download_modal_title'),
      showCollectionOverlay: false,
    }
  }

  componentDidMount() {
    this.fetchFilters()
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, nextProps) {
    const { alert, updatedProducts } = this.props
    const oldTimestamp = prevProps.alert ? prevProps.alert.timestamp : null
    const { message, type, timestamp } = alert
    const { id: batchId } = updatedProducts

    if (oldTimestamp !== timestamp) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ alert: { message, type } })
    }

    if (prevProps.updatedProducts.id !== batchId) {
      this.updateProductsState(updatedProducts)
    }
  }

  crudFilter = (postFilters, id, method = 'POST') => {
    let url = quickFiltersBaseUrl

    if (id) {
      url = quickFiltersBaseUrl + id
    }

    this.setState({
      crudError: null,
      method,
      id,
      postFilters,
    })

    const crudMethod = (() => {
      switch (method) {
        case 'POST':
          return post
        case 'PATCH':
          return patch
        case 'DELETE':
          return del
      }
    })()

    crudMethod(url, postFilters, undefined)
      .then(() => {
        this.setState({ showFilterModal: false })
        this.fetchFilters()
      })
      .catch(() => {
        this.setState({
          crudError: errorFetch(),
        })
      })
  }

  fetchFilters = () => {
    const url = quickFiltersBaseUrl
    this.setState({
      filtersLoading: true,
      filtersData: [],
    })
    get(url)
      .then((response) => {
        this.setState({
          filtersData: response,
          filtersLoading: false,
        })
        this.filterActions(response)
      })
      .catch(() => {
        this.setState({ crudError: errorFetch() })
      })
  }

  filterActions = (response) => {
    const { method, id, postFilters, activeFilterId } = this.state
    if (method === 'POST') {
      const activeFilter = response.find((filter) => filter.key === postFilters.display_name)
      const activeFilterIndex = response.findIndex(
        (filter) => filter.key === postFilters.display_name
      )
      const newId = activeFilter.id
      this.setActiveFilterId(newId)
      this.setSelectedFolder(activeFilterIndex)
      this.redirectToNewFilter(postFilters)
      this.setState({ newFilterName: postFilters.display_name })
    }
    if (method === 'PATCH' && activeFilterId === id) {
      this.redirectToNewFilter(postFilters)
    }
    if (method === 'DELETE' && activeFilterId === id) {
      this.resetLink()
    }
  }

  resetLink = () => {
    const { history } = this.props
    history.push('/turbosquid/products')
    this.resetSelectedFolder()
  }

  setSelectedFolder = (index) => {
    const { filtersData } = this.state
    const { data } = filtersData[index]
    const term = this.getTermFromQuery(data)
    this.setState({ selectedFolder: index, selectedFilter: filtersData[index], term })
  }

  resetSelectedFolder = () => {
    this.setState({ selectedFolder: null, activeFilterId: null })
  }

  redirectToNewFilter = (postFilters) => {
    const { history } = this.props
    const baseURL = '/turbosquid/products?'
    const toCreateString = postFilters.quick_filter
    let url = ''

    Object.entries(toCreateString).forEach(([key, value]) => { url += buildUrlParams(key, value) })
    const finalUrl = baseURL + url.slice(1)
    history.push(finalUrl)
  }

  updateProductsState = (response = {}) => {
    const productsData = {}
    const errors = []
    let alert
    const { data: stateData } = this.state

    response.attributes.products.forEach((product) => {
      if (product.attributes.errors) {
        errors.push(product.attributes.errors)
      } else {
        const { price, status, name, features } = product.attributes
        productsData[product.id] = { price, status, name, features }
      }
    })

    const data = stateData.map((oldData) => {
      const product = productsData[parseInt(oldData.turbosquid_id, 10)]

      if (product) {
        if (product.price !== undefined) {
          const newPrice = `$${new Decimal(product.price)
            .dividedBy(100)
            .toFixed(2)}`
          return { ...oldData, price: newPrice }
        } if (product.status !== undefined) {
          if (product.status === 'deleted') {
            return null
          }
          return { ...oldData, status: product.status }
        } if (product.name !== undefined) {
          return { ...oldData, name: product.name }
        } if (product.features !== undefined) {
          return { ...oldData, feature_assignments: product.features }
        }
      }
      return oldData
    }).filter((product) => product !== null)

    if (errors.length > 0) {
      let errorMessage = I18n.t('turbosquid_products.messages.partial_update')
      errorMessage = errorMessage.concat(' ', errors.flat().join('; '), '.')
      alert = {
        type: 'danger',
        message: errorMessage,
      }
    } else {
      alert = { type: 'success', message: I18n.t('turbosquid_products.messages.update_done'), timeout: 5000 }
    }

    this.setState({ alert, data })
    window.scrollTo(0, 0)
  }

  getTermFromQuery = (searchObject) => {
    const searchTerm = searchObject.search || searchObject.term || searchObject.query
    return searchTerm
  }

  // eslint-disable-next-line no-unused-vars
  sortChange = (newSorted, column, additive) => {
    const { search } = this.state
    const stateSearch = queryString.parse(search)
    const { history } = this.props
    const { id, desc } = newSorted[0]
    stateSearch.page = 1
    stateSearch.sort = desc ? `${id},desc` : id
    const newStateDiff = { page: 1, search: queryString.stringify(stateSearch) }
    this.setState(newStateDiff)
    history.push({
      pathname: '/turbosquid/products',
      search: encodeQuery(stateSearch),
      state: newStateDiff,
    })
  }

  fetchData = (state, instance, showLoading = true) => {
    this.setState({ loading: showLoading }, () => {
      const { location } = this.props
      const { search } = location
      ProductsService.getProducts(state, search)
        .then((response) => {
          const pendingAssets = response.pending_assets !== 'error' ? response.pending_assets : []
          const pendingUpdateAssets = response.pending_update_assets !== 'error' ? response.pending_update_assets : []
          const searchObject = queryString.parse(search)
          const nextState = {
            data: response.data,
            pendingAssets,
            pendingUpdateAssets,
            pages: response.meta.total_pages,
            total: response.meta.total_count,
            aggregations: response.meta.aggregations,
            search: queryString.stringify(searchObject),
            loading: false,
            hasSyndication: response.has_syndication,
          }
          this.setState(nextState, () => {
            const {
              page,
              pendingAssets: currentPendingAssets,
              pendingUpdateAssets: currentPendingUpdateAssets,
            } = this.state
            if (
              page === 1
              && (currentPendingAssets.length > 0 || currentPendingUpdateAssets.length > 0)
            ) {
              instance.forceUpdate()
              // to avoid requesting page 2 due to the way page is set in remoteData.js
              const modState = { ...this.state, page: 0 }
              this.pendingAssetsTimeout = setTimeout(
                () => this.fetchData(modState, instance, false), PENDING_ASSETS_POLL_INTERVAL
              )
            }
          })
        })
    })
  }

  getCheckedProductIds = () => (this.checkboxTable ? this.checkboxTable.selection : [])

  // Filter products that can be bulk updated
  getUpdateableProducts = () => {
    const { data: products } = this.state
    const checkedIds = this.getCheckedProductIds()
    return products.filter((product) => checkedIds.includes(product.turbosquid_id))
  }

  buildAdditionalColumns = (partners) => {
    let additionalColumns = []

    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in partners) {
      const partner = partners[key]

      if (partner.selected === true) {
        additionalColumns = additionalColumns.concat(partnerColumns(partner, key))
      }
    }
    return additionalColumns
  }

  updateAdditionalData = (event) => {
    const { selectedSyndicationPartners } = this.state

    let tableColumns = this.defaultColumns

    selectedSyndicationPartners[event].selected = !selectedSyndicationPartners[event].selected
    const additionalColumns = this.buildAdditionalColumns(selectedSyndicationPartners)

    if (additionalColumns.length > 0) {
      tableColumns = tableColumns.concat(additionalColumns)
    }

    this.setState({ selectedSyndicationPartners, tableColumns })
  }

  handleBulkUpdateSubmit = (data) => {
    const { handleBulkUpdateSubmit: propsHandleBulkUpdateSubmit } = this.props
    this.setState({
      showpriceModal: false,
      showStatusModal: false,
      showRemovalModal: false,
      showDownloadModal: false,
    })
    propsHandleBulkUpdateSubmit(data)
  }

  // Callback that toggle the price modal and checks products are selected
  togglePriceModal = (value) => {
    const checkedProductIds = this.getCheckedProductIds()

    if (checkedProductIds.length) {
      this.setState({ showpriceModal: value })
    } else {
      const message = I18n.t('turbosquid_products.messages.no_products_checked')
      this.setState({ alert: { type: 'danger', message } })
    }
  }

  toggleStatusModal = (value) => {
    const checkedProductIds = this.getCheckedProductIds()

    if (checkedProductIds.length) {
      this.setState({ showStatusModal: value })
    } else {
      const message = I18n.t('turbosquid_products.messages.no_products_checked')
      this.setState({ alert: { type: 'danger', message } })
    }
  }

  toggleDownloadModal = (value) => {
    const checkedProductIds = this.getCheckedProductIds()

    if (checkedProductIds.length === 1) {
      this.setState({ showDownloadModal: value })
    } else {
      const message = I18n.t('turbosquid_products.messages.single_product_for_download')
      this.setState({ alert: { type: 'danger', message } })
    }
  }

  handleSelectionChange = (length, selections = []) => {
    const allowDuplicate = length <= 1 && selections.every((p) => p.status === 'online')
    const allowDownload = length <= 1

    this.setState({ allowDuplicate, allowDownload })
  }

  duplicateProduct = () => {
    const checkedProductIds = this.getCheckedProductIds()

    if (checkedProductIds.length === 1) {
      const productId = checkedProductIds[0]
      const url = `${gon.turbosquid_products_path}/${productId}/duplicate`
      const params = {
        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
        productId }
      this.setState({ showOverlay: true })
      post(url, params)
        .then((response) => {
          const draftId = response.draft.id
          const editURL = draftEditPageUrl(draftId)
          this.setState({ showOverlay: false })
          window.location.href = editURL
        })
        .catch((error) => {
          this.setState({ alert: { type: 'danger', message: error.message } })
          this.setState({ showOverlay: false })
        })
    } else {
      const message = I18n.t('turbosquid_products.messages.single_product_for_duplicate')
      this.setState({ alert: { type: 'danger', message }})
    }
  }

  toggleRemovalModal = (value) => {
    const checkedProductIds = this.getCheckedProductIds()

    if (checkedProductIds.length) {
      this.setState({ showRemovalModal: value })
    } else {
      const message = I18n.t('turbosquid_products.messages.no_products_checked')
      this.setState({ alert: { type: 'danger', message } })
    }
  }

  toggleSidebar = () => {
    const { toggleSidebar } = this.state
    this.setState({ toggleSidebar: !toggleSidebar })
  }

  selectedFilterData = (id) => {
    const { filtersData } = this.state
    Object.entries(filtersData).forEach(([key, value]) => {
      if (value.id === id) {
        return this.setState({ selectedFilter: value })
      }
      return key
    })
  }

  toggleFilterModalWithCustomTitle = (bool, title, id) => {
    const { filterModalTitle } = this.state
    let modalTitle
    if (id) {
      this.selectedFilterData(id)
    } else {
      this.setState({ selectedFilter: null })
    }

    if (typeof title === 'undefined') {
      modalTitle = filterModalTitle
    } else {
      const titles = {
        new: I18n.t('turbosquid_products.filter_titles.modal_new_filter'),
        edit: I18n.t('turbosquid_products.filter_titles.modal_edit_filter'),
      }
      modalTitle = titles[title]
    }
    this.setState({
      crudError: null,
      showFilterModal: bool,
      filterModalTitle: modalTitle,
    })
  }

  // Callback that is passed into an AlertBox component and close it
  resetAlertBox = () => {
    const { resetParentAlert } = this.props
    this.setState({ alert: {} })
    resetParentAlert()
  }

  renderAlertBox = () => {
    const { alert } = this.state
    if (alert.message) {
      return (
        <AlertBox
          type={alert.type}
          message={alert.message}
          timeout={alert.timeout}
          resetAlertBox={this.resetAlertBox}
        />
      )
    }
    return null
  }

  setActiveFilterId = (id) => {
    this.setState({ activeFilterId: id })
  }

  handleChange = (e) => {
    const { target } = e
    const { name, value } = target
    this.setState({ [name]: value })
  }

  handleEditCellSubmit = (data) => {
    const { name, value, turbosquid_id: turbosquidId, alert: alertMessage } = data
    const { handlePendingSubmitsChanges } = this.props
    const { handleBulkUpdateSubmit } = this

    if (alertMessage !== undefined) {
      this.setState({ alert: alertMessage })
      window.scrollTo(0, 0)
    } else {
      this.setState({ inProgress: true })

      const submitId = new Date().getTime()
      handlePendingSubmitsChanges(submitId)

      let productAttributes
      if (name === 'name') {
        productAttributes = { name: value }
      } else {
        const priceCents = parseInt(value * 100, 10)
        productAttributes = { price: priceCents }
      }
      const products = [{
        id: turbosquidId,
        type: 'product',
        attributes: productAttributes,
      }]
      const url = gon.turbosquid_product_batches_path
      const params = {
        authenticity_token: document.querySelector('meta[name="csrf-token"]').content,
        products }

      PendingAssetsService.saveAssetChanges(url, params)
        .then((response) => {
          const productIds = [turbosquidId]
          const batchData = { batchID: response.id, productIds }
          this.setState({ inProgress: false })
          handlePendingSubmitsChanges(submitId)
          handleBulkUpdateSubmit(batchData)
        })
        .catch((error) => {
          this.setState({ inProgress: false })
          handlePendingSubmitsChanges(submitId)
          handleBulkUpdateSubmit({ error })
        })
    }
  }

  renderEditableName = (cellInfo) => {
    const { pendingProductIds } = this.props
    const { pendingUpdateAssets } = this.state
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const newPendingProductIds = pendingProductIds.concat(pendingUpdateProductIds)
    /* eslint-disable */
    return (
      <ThumbnailCell
        {...cellInfo.original}
        pendingProductIds={newPendingProductIds}
        handleEditCellSubmit={this.handleEditCellSubmit}
      />
    )
    /* eslint-enable */
  }

  renderEditablePrice = (cellInfo) => {
    const { pendingProductIds } = this.props
    const originalPrice = cellInfo.original.price
    const { pendingUpdateAssets } = this.state
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const newPendingProductIds = pendingProductIds.concat(pendingUpdateProductIds)
    /* eslint-disable */
    return (
      <PriceCell
        {...cellInfo.original}
        originalPrice={originalPrice}
        pendingProductIds={newPendingProductIds}
        handleEditCellSubmit={this.handleEditCellSubmit}
      />
    )
    /* eslint-enable */
  }

  renderIdCell = (cellInfo) => {
    const { pendingProductIds } = this.props
    const { pendingUpdateAssets } = this.state
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const newPendingProductIds = pendingProductIds.concat(pendingUpdateProductIds)
    /* eslint-disable */
    return (
      <IdCell
        {...cellInfo.original}
        pendingProductIds={newPendingProductIds}
      />
    )
    /* eslint-enable */
  }

  renderActionCell = (cellInfo) => {
    const { pendingProductIds } = this.props
    const { pendingUpdateAssets } = this.state
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const newPendingProductIds = pendingProductIds.concat(pendingUpdateProductIds)
    const { turbosquid_id, media_type } = cellInfo.original

    return (
      <ActionCell
        media_type={media_type}
        turbosquid_id={turbosquid_id}
        pendingProductIds={newPendingProductIds}
      />
    )
  }

  renderFeatureCell = (cellInfo) => {
    const { pendingProductIds } = this.props
    const { pendingUpdateAssets } = this.state
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const newPendingProductIds = pendingProductIds.concat(pendingUpdateProductIds)
    /* eslint-disable */
    return (
      <FeatureCell
        {...cellInfo.original}
        pendingProductIds={newPendingProductIds}
      />
    )
    /* eslint-enable */
  }

  renderDateModifiedCell = (cellInfo) => {
    const { pendingProductIds } = this.props
    const { pendingUpdateAssets } = this.state
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const newPendingProductIds = pendingProductIds.concat(pendingUpdateProductIds)
    return (
      <DateCell
        value={cellInfo.value}
        displayOptions={{ classes: 'classic_updated_at_cell' }}
        pendingProductIds={newPendingProductIds}
        turbosquid_id={cellInfo.original.turbosquid_id}
        is_pending_update={cellInfo.original.is_pending_update}
        type={cellInfo.original.type}
      />
    )
    /* eslint-enable */
  }

  updateDownloadModalTitle = (title) => {
    this.setState({ downloadModalTitle: title })
  }

  createCollection = () => {
    const checkedProductIds = this.getCheckedProductIds()

    if (this.validCollectionAssets()) {
      this.setState({
        showCollectionOverlay: true,
      })

      createCollectionAssets(checkedProductIds)
        .then((response) => {
          if (!response?.draft?.id) {
            this.setState({ alert: { type: 'danger', message: I18n.t('turbosquid.products.index.create_collection.failed') }, showCollectionOverlay: false })
          } else {
            window.location.href = draftEditPageUrl(response.draft.id)
          }
        })
        .catch((_error) => {
          this.setState({ alert: { type: 'danger', message: I18n.t('turbosquid.products.index.create_collection.failed') }, showCollectionOverlay: false })
        })
    } else {
      const message = I18n.t('turbosquid_products.messages.multiple_product_for_create_collections')
      this.setState({ alert: { type: 'danger', message } })
    }
  }

  preloadImage = (url) => (
    <img style={{ display: 'none' }} src={url} alt="" />
  )

  validCollectionAssets = () => {
    const { data } = this.state
    const checkedProductIds = this.getCheckedProductIds()
    const validStatus = ['online']
    const invalidProduct = data.find((p) => (
      checkedProductIds.includes(p.turbosquid_id)
      && !validStatus.includes(p.status.toLowerCase())
    ))

    return checkedProductIds.length > 1 && !invalidProduct
  }

  render() {
    const {
      sorted,
      total,
      pages,
      data,
      pendingAssets,
      pendingUpdateAssets,
      pageSize,
      loading,
      aggregations,
      tableColumns,
      hasSyndication,
      selectedSyndicationPartners,
      search,
      showpriceModal,
      showStatusModal,
      showDownloadModal,
      showRemovalModal,
      toggleSidebar,
      showFilterModal,
      filterModalTitle,
      filtersData,
      selectedFilter,
      filtersLoading,
      crudError,
      newFilterName,
      activeFilterId,
      selectedFolder,
      term,
      allowDuplicate,
      showOverlay,
      allowDownload,
      downloadModalTitle,
      showCollectionOverlay,
    } = this.state

    const {
      location,
      // eslint-disable-next-line camelcase
      pendingProductIds: pending_product_ids,
      handlePendingSubmitsChanges,
    } = this.props

    let size = gon.default_page_size
    const searchObject = queryString.parse(location.search)

    if (searchObject.size) {
      size = parseInt(searchObject.size, 10)
    }

    const combinedData = pendingAssets.concat(data)
    const pendingUpdateProductIds = pendingUpdateAssets.map((product) => product.turbosquid_id)
    const pendingProductIds = pending_product_ids.concat(pendingUpdateProductIds)
    const newCombinedData = combinedData.map(
      (asset) => (
        { ...asset, is_pending_update: pendingUpdateProductIds.includes(asset.turbosquid_id) }
      )
    )

    const sidebar = (
      <Sidebar
        title={I18n.t('turbosquid_products.filter_titles.sidebar_filter')}
        icon={toggleSidebar ? 'fa fa-angle-double-left' : 'fa fa-filter'}
        toggle={this.toggleSidebar}
        toggleClass={toggleSidebar ? 'toggled' : ''}
      >
        <SidebarContent
          filters={filtersData}
          addFilter={() => this.toggleFilterModalWithCustomTitle(true, 'new')}
          editFilter={(id) => this.toggleFilterModalWithCustomTitle(true, 'edit', id)}
          filtersLoading={filtersLoading}
          crudError={crudError}
          newFilterName={newFilterName}
          setActiveFilterId={this.setActiveFilterId}
          activeFilterId={activeFilterId}
          resetLink={this.resetLink}
          setSelectedFolder={this.setSelectedFolder}
          selectedFolder={selectedFolder}
          resetSelectedFolder={this.resetSelectedFolder}
        />
      </Sidebar>
    )

    const filterModalContent = (
      <FilterModalContent
        addFilter={this.crudFilter}
        deleteFilter={this.crudFilter}
        updateFilter={this.crudFilter}
        selectedFilter={selectedFilter}
        filtersData={filtersData}
        aggregations={aggregations}
        crudError={crudError}
        activeFilterId={activeFilterId}
      />
    )

    const disabled = gon.block_publishing

    return (
      <>
        <div className="my-products">
          <div className="page-toolbar">
            <div className="search-and-actions">
              <SearchForm
                {...this.props} //eslint-disable-line
                term={term}
                aggregations={aggregations}
                handleChange={this.handleChange}
                updatePriceModalVisibility={this.togglePriceModal}
              />
              <div className="in-page-actions">
                <FilterBulkActions
                  updatePriceModalVisibility={this.togglePriceModal}
                  updateStatusModalVisibility={this.toggleStatusModal}
                  updateDownloadModalVisibility={this.toggleDownloadModal}
                  updateRemovalModalVisibility={this.toggleRemovalModal}
                  duplicateProduct={this.duplicateProduct}
                  createCollection={this.createCollection}
                  allowDuplicate={allowDuplicate}
                  allowDownload={allowDownload}
                  disabled={disabled}
                />
                {hasSyndication && (
                  <FilterAdditionalData
                    partners={selectedSyndicationPartners}
                    button_title="Additional Data"
                    button_key="dropdown_additional_data"
                    updateAdditionalData={this.updateAdditionalData}
                  />
                )}
                <CreateCollection
                  createCollection={this.createCollection}
                  disabled={disabled || !this.validCollectionAssets()}
                />
              </div>
            </div>
            <div className="nav-pills action-buttons">
              <CreateNewProduct alignClass="pull-right" />
              <ViewDrafts alignClass="pull-right" />
            </div>
            <FiltersMyProducts
              {...this.props} //eslint-disable-line
              aggregations={aggregations}
            />
          </div>
          {this.renderAlertBox()}
          <div className="my-products-content clearfix">
            <div className="my-products-results">
              {sidebar}
              <SelectableReactTable
                manual
                data={newCombinedData}
                columns={tableColumns}
                keyField="turbosquid_id"
                loading={loading}
                onFetchData={this.fetchData}
                onPageChange={this.pageChange}
                onSortedChange={this.sortChange}
                handleSelectionChange={this.handleSelectionChange}
                showPagination={false}
                pages={pages}
                pageSize={pageSize}
                className="-striped my-products-table"
                minRows={0}
                defaultSorted={sorted}
                filtered={location.search}
                pendingProductIds={pendingProductIds}
                ref={(table) => { this.checkboxTable = table }}
              />
            </div>
            <Paginator
              total={total}
              page={parseInt(searchObject.page || 1, 10)}
              pages={pages}
              pageSize={size}
              search={search}
              pageSizes={gon.page_sizes}
            />
          </div>
          <GenericModal
            title={I18n.t('turbosquid_products.price_modal_title')}
            className="bulk"
            show={showpriceModal}
            hideModal={() => this.togglePriceModal(false)}
          >
            <PriceUpdateForm
              products={this.getUpdateableProducts()}
              handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
              handlePendingSubmitsChanges={handlePendingSubmitsChanges}
            />
          </GenericModal>
          <GenericModal
            title={I18n.t('turbosquid_products.status_modal_title')}
            className="bulk"
            show={showStatusModal}
            hideModal={() => this.toggleStatusModal(false)}
          >
            <StatusUpdateForm
              products={this.getUpdateableProducts()}
              handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
              handlePendingSubmitsChanges={handlePendingSubmitsChanges}
            />
          </GenericModal>
          <GenericModal
            title={downloadModalTitle}
            className="bulk"
            show={showDownloadModal}
            hideModal={() => this.toggleDownloadModal(false)}
          >
            <DownloadModal
              products={this.getUpdateableProducts()}
              handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
              updateDownloadModalTitle={this.updateDownloadModalTitle}
            />
          </GenericModal>
          <GenericModal
            title={I18n.t('turbosquid_products.removal_modal_title')}
            className="bulk"
            show={showRemovalModal}
            hideModal={() => this.toggleRemovalModal(false)}
          >
            <RemovalForm
              products={this.getUpdateableProducts()}
              handleBulkUpdateSubmit={this.handleBulkUpdateSubmit}
              handlePendingSubmitsChanges={handlePendingSubmitsChanges}
            />
          </GenericModal>
          <GenericModal
            title={filterModalTitle}
            show={showFilterModal}
            className="bulk filter-modal"
            hideModal={() => this.toggleFilterModalWithCustomTitle(false)}
          >
            {filterModalContent}
          </GenericModal>
        </div>
        {
          showOverlay && (
            <SpinnerOverlay>
              { I18n.t('turbosquid.products.product_files_panel.processing') }
            </SpinnerOverlay>
          )
        }

        {this.preloadImage(collectionLoadingTransparent)}
        { showCollectionOverlay && (
          <SpinnerOverlay
            bodyStyle={{
              flexDirection: 'column',
            }}
            renderSpinner={(
              <div>
                <img src={collectionLoadingTransparent} alt="" />
              </div>
            )}
          >
            {I18n.t('turbosquid.products.index.assembling_collection')}
            &nbsp;
            ...
          </SpinnerOverlay>
        )}
      </>
    )
  }
}

MyProducts.propTypes = {
  location: PropTypes.object,
  alert: PropTypes.object,
  updatedProducts: PropTypes.object,
  history: PropTypes.object,
  handleBulkUpdateSubmit: PropTypes.func,
  resetParentAlert: PropTypes.func,
  handlePendingSubmitsChanges: PropTypes.func,
  pendingProductIds: PropTypes.array,
}

MyProducts.defaultProps = {
  location: null,
  alert: null,
  updatedProducts: null,
  history: null,
  handleBulkUpdateSubmit: null,
  resetParentAlert: null,
  handlePendingSubmitsChanges: null,
  pendingProductIds: [],
}

export default MyProducts
