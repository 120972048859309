import React, { useContext } from 'react'
import PreviewSpec from './PreviewSpec'
import { ProductContext } from '../../../contexts/ProductContext'

const i18nOptions = {
  scope: 'turbosquid.products.product_images_panel',
}

export default function PreviewSpecs({
  turntableRequired,
  searchPreviewRequired,
  wireframeRequired,
  uvRequired,
  previewCount,
  wireframeCount,
  uvCount,
  turntableCount,
  searchPreviewCount
}) {
  const { tips } = useContext(ProductContext)
  return (
    <div className="image-icon-hint-wrapper">
      <PreviewSpec
        count={searchPreviewCount}
        required={searchPreviewRequired ? 1 : 0}
        title={I18n.t('search_image', i18nOptions)}
        translationKey="search_image"
        className="search_image image-icon-hint_square"
        learnMoreUrl={tips.search.url}
      />
      <PreviewSpec
        count={previewCount}
        required={5}
        title={I18n.t('beauty', i18nOptions)}
        translationKey="beauty_shots"
        className="beauty_shots image-icon-hint_16_9"
        learnMoreUrl={tips.regular.url}
      />
      <PreviewSpec
        count={wireframeCount}
        required={wireframeRequired ? 1 : 0}
        title={I18n.t('wireframe', i18nOptions)}
        translationKey="wireframe"
        className="wireframe image-icon-hint_16_9"
        learnMoreUrl={tips.wireframe.url}
      />
      <PreviewSpec
        count={turntableCount}
        required={turntableRequired ? 1 : 0}
        title="360°"
        translationKey="turntable"
        className="turntable image-icon-hint_16_9"
        learnMoreUrl={tips.turntable.url}
      />
      <PreviewSpec
        count={uvCount}
        required={uvRequired ? 1 : 0}
        title={I18n.t('uv', i18nOptions)}
        translationKey="uv_map"
        className="uv_map image-icon-hint_square"
        learnMoreUrl={tips.uv.url}
      />
    </div>
  )
}
