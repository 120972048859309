/* eslint-disable no-param-reassign */
/* global gon, I18n */
import { createSlice } from '@reduxjs/toolkit'
import { omit, set, isNumber, filter, map, flatten, intersection } from 'lodash'
import { conditionalMediaTypeFields } from '../../../utils/SaveValidations'
import { arrayToList } from './publisher_reducer_methods'
import { isCertOfType } from '../selectors/formSelectors'
import { mediaTypeDropdownData, associatedFileTypes } from '../../products_lookups'
import { dismissNotification } from '../actions/shared_actions'
import { arrayFrom } from '../../../utils/ProductFiles'
import { REGULAR, UV } from '../../../components/products/constants'


const DEFAULT_IMAGE_TYPE = 'image'

const priceToFixed = (price) => {
  const value = parseFloat(price)

  return (value || value === 0) ? value.toFixed(2) : ''
}

const deleteError = (errors, key) => omit(errors, [key])

const getDataFrom = ({ product, features }) => {
  if (!product && !features) {
    return {}
  }

  const {
    draft_id: draftId,
    certifications,
    status,
    created_at: createdAt,
    updated_at: updatedAt,
    license,
    previews,
  } = product
  const data = { draftId, certifications, status, createdAt, updatedAt, license, previews }

  if (features) {
    const objectFeaturePathIds = (gon.feature_graph_object_path_id || '').split('/')
    const objectFeatures = features.filter((feature) => (
      intersection(feature.ancestry_ids, objectFeaturePathIds).length === objectFeaturePathIds.length))
    data.featureIds = objectFeatures.map((feature) => feature.id)
  }

  if (data.draftId && data.draftId === '0') {
    data.draftId = null
  }

  return data
}

const manageErrors = (formState, error, key) => {
  if (error !== undefined && error !== null) {
    set(formState, ['formValidationErrors', key], error)
  } else {
    formState.formValidationErrors = deleteError(formState.formValidationErrors, key)
  }
  return formState.formValidationErrors
}

const getTermAlerts = (state, terms = [], key, expectedLicense) => {
  const alerts = []
  if (terms.length && state.license === expectedLicense) {
    const term = arrayToList(terms)
    const message = I18n.t(key, { term, scope: 'turbosquid.products.edit' })

    alerts.push({
      notifications: [{ message }],
      alert_panels: ['license'],
      type: 'royalty_free_editorial_uses_only',
      level: 'warning',
    })
  }
  return alerts
}

const requiresEditorialOrUnbranded = (constraint = {}, featureIds = []) => {
  const {
    editorial_uses_only_license: editorialUsesOnlyLicense,
    extended_uses_license: extendedUsesLicense,
  } = constraint.license
  return (
    editorialUsesOnlyLicense.length
    && extendedUsesLicense.length
    && !extendedUsesLicense.find((featureId) => featureIds.includes(featureId))
  )
}

const requiresEditorialOrUnbrandedAlerts = (state, message, license) => {
  // if (!state.constraints || (state.constraints.length === 0 && state.featureIds.length === 0)) {
  if (!state.constraints || state.constraints.length === 0) {
    return []
  }
  const terms = state.constraints
    .filter((constraint) => requiresEditorialOrUnbranded(constraint, state.featureIds))
    .map((constraint) => constraint.term)

  return getTermAlerts(state, terms, message, license)
}

const requiresEditorial = (constraint = {}) => {
  const {
    editorial_uses_only_license: editorialUsesOnlyLicense,
    extended_uses_license: extendedUsesLicense,
  } = constraint.license
  return editorialUsesOnlyLicense.length && !extendedUsesLicense.length
}

const requiresEditorialAlerts = (state) => {
  if (!state.constraints || state.constraints.length === 0) {
    return []
  }
  const terms = state.constraints.filter(requiresEditorial).map((constraint) => constraint.term)
  return getTermAlerts(state, terms, 'editorial_required', 'royalty_free_all_extended_uses')
}

export const processStateChanges = (state) => {
  const editorialAlerts = requiresEditorialAlerts(state)
  const unbrandedAlerts = requiresEditorialOrUnbrandedAlerts(state, 'editorial_required_term_unbranded', 'royalty_free_all_extended_uses')
  const alerts = editorialAlerts.concat(unbrandedAlerts)
  return alerts
}

const getAmbiguousTermAlerts = (state) => (
  requiresEditorialOrUnbrandedAlerts(state, 'editorial', 'royalty_free_editorial_uses_only')
)

const preprocessFileFields = (fields) => {
  const newFields = {}
  Object.keys(fields).forEach((key) => {
    const value = fields[key]
    newFields[key] = value === '_' ? '' : value
  })
  return newFields
}

const updateField = (state, action, field) => {
  const { payload } = action
  const { errors, draftSavePendingChanges = true } = payload

  state.changesToFieldsWithTermConstraints = payload.constraintsPending
  state.turbosquid_product_form.touched[field] = true
  state.turbosquid_product_form.formValidationErrors = manageErrors(state.turbosquid_product_form, errors, field)
  if (state.turbosquid_product_form.values[field] !== payload[field]) {
    state.turbosquid_product_form.values[field] = payload[field]
    state.turbosquid_product_form.draftSavePendingChanges = draftSavePendingChanges
  }
}

const updateConstraintsState = (state, action, field) => {
  state.constraints = action.payload.constraints
}

const getFormFrom = (source = {}, formName) => {
  const tags = (source.tags ?? []).join(' ')
  const price = priceToFixed(source.price)
  const mediaType = mediaTypeDropdownData[0].id
  const newSource = {}
  const {
    certifications,
    draft_id: draftId,
    id: productId,
  } = gon.product

  Object.keys(source).forEach((key) => {
    if (source[key] !== null) {
      newSource[key] = source[key]
    }
  })
  const {
    animated = false,
    geometry = 0,
    materials = false,
    polygons = '',
    unwrapped_u_vs: unwrappedUVs = 0,
    rigged = false,
    textures = false,
    uv_mapped: uvMapped = false,
    vertices = '',
    width = '',
    description = '',
    name = '',
    height = '',
    tileable = false,
    alpha_channel: alphaChannel = false,
    multiple_layers: multipleLayers = false,
    frame_rate: frameRate = 0,
    color_depth: colorDepth = 0,
    length = '',
    biped = false,
    loopable = false,
  } = newSource

  const stemcellObject = (certifications && certifications.find((certification) => (
    isCertOfType(certification, 'stemcell_v2')
  ))) || {}
  const { id: stemcell } = stemcellObject

  const certifiedPreviews = certifications.filter((e) => e.id === 'certified_previews').length > 0

  return {
    certifications,
    [formName]: {
      values: {
        animated,
        geometry,
        materials,
        polygons: isNumber(polygons) ? polygons : '',
        unwrappedUVs,
        rigged,
        textures,
        uvMapped,
        vertices: isNumber(vertices) ? vertices : '',
        width: width || '',
        description,
        name,
        price,
        tags,
        frameRate,
        colorDepth,
        height: height || '',
        tileable,
        alphaChannel,
        multipleLayers,
        length: length || '',
        biped,
        loopable,
        mediaType,
        stemcell,
        certifiedPreviews,
        oldCertifiedPreviews: certifiedPreviews,
      },
      errors: {},
      touched: {},
      formValidationErrors: {},
    },
    changesToFieldsWithTermConstraints: false,
    greyedOutSort: false,
    previewDrops: 0,
    previewsLength: 0,
    disableDragAnimation: true,
    disableCertifiedPreviewChange: false,
    constraints: [],
    dataValidationPassed: { status: true },
    draftSavePendingSort: false,
    invalidPreviewCount: false,
    skipDiffPreviews: true,
    creatingDraft: false,
    disableDragSort: false,
    publishValidationTriggered: false,
    asyncQueue: {},
    disableLeavePagePrompt: false,
    displayAlerts: false,
  }
}

export const getFormInitialState = (formName) => {
  const state = getDataFrom(gon)
  if (state.draftId) {
    state.hadDraft = true
  }
  state.constraints = []

  const alerts = processStateChanges(state)
  return { ...state, alerts, ...getFormFrom(gon.product, formName) }
}

const mapThumbnailType = (type) => {
  switch (type) {
    case REGULAR:
      return DEFAULT_IMAGE_TYPE
    case UV:
      return 'uv_map'
    default:
      return type
  }
}

export const formSlice = createSlice({
  name: 'form',
  initialState: {},
  reducers: {
    syncDraft: (state, { payload: { id, name, updatedAt, createdAt, certifications }}) => {
      const { certifiedPreviews } = certifications
      const currentName = state.turbosquid_product_form.values.name
      state.draftId = id ? id.toString() : null
      state.turbosquid_product_form.values.name = (!!currentName ? currentName : name) ?? ''
      state.turbosquid_product_form.values.certifiedPreviews = certifiedPreviews
    },
    syncFiles: (state, action) => {
      const { native, preview, associated } = action.payload
      state.mainFiles = arrayFrom(native)
      state.associatedFiles = arrayFrom(associated)
      state.previews = arrayFrom(preview).map(f => ({
        ...f,
        image_type: mapThumbnailType(f.attributes.thumbnailType)
      })).sort((a, b) => a.index - b.index)
    },
    displayAlerts: (state) => {
      state.displayAlerts = true
    },
    startSubmit: (state) => {
      state.turbosquid_product_form.draftSaving = true
    },
    changeInput: (state, { payload: { errors, formName, name, value } }) => {
      const normalizedError = errors && errors.errors ? errors.errors : errors
      if (normalizedError && normalizedError.length > 0) {
        state[formName].formValidationErrors[name] = normalizedError
      } else {
        state[formName].formValidationErrors = deleteError(state.turbosquid_product_form.formValidationErrors, name)
      }

      if (String(state[formName].values[name]) !== String(value)) {
        state[formName].values[name] = value
        state[formName].touched[name] = true
        state[formName].draftSavePendingChanges = true
      }

      if (name === 'certifiedPreviews') {
        state.displayAlerts = false
      }
    },
    updateTags: (state, action) => {
      updateField(state, action, 'tags')
      updateConstraintsState(state, action, 'tags')
    },
    updateMediaType: (state, { payload: { value } }) => {
      const mediaTypeFields = conditionalMediaTypeFields(value)
      const allMediaTypeFields = conditionalMediaTypeFields()
      const { errors } = state.turbosquid_product_form

      state.turbosquid_product_form.formValidationErrors = Object.keys(errors).filter((key) => (
        !allMediaTypeFields.includes(key)
        || (allMediaTypeFields.includes(key) && mediaTypeFields.includes(key))
      )).reduce((obj, key) => {
        obj[key] = errors[key]
        return obj
      }, {})

      if (state.turbosquid_product_form.values.mediaType !== value) {
        state.turbosquid_product_form.values.mediaType = value
        state.turbosquid_product_form.draftSavePendingChanges = true
      }
    },
    updateName: (state, action) => {
      const { constraints } = action.payload

      if (constraints) {
        updateConstraintsState(state, action, 'name')
      }
      updateField(state, action, 'name')
    },
    updateDescription: (state, action) => {
      const { constraints } = action.payload

      if (constraints) {
        updateConstraintsState(state, action, 'description')
      }

      updateField(state, action, 'description')
    },
    updatePrice: (state, { payload: { price, errors } }) => {
      const fixedPrice = priceToFixed(price)
      if (state.turbosquid_product_form.values.price !== fixedPrice) {
        state.turbosquid_product_form.values.price = fixedPrice
        state.turbosquid_product_form.draftSavePendingChanges = true
      }
      state.turbosquid_product_form.formValidationErrors = manageErrors(state.turbosquid_product_form, errors, 'price')
    },
    updateConstraints: (state, { payload: { constraints } }) => {
      state.constraints = constraints
      state.termConstraintNotifications = {}
      state.changesToFieldsWithTermConstraints = false
    },
    updateFeatures: (state, { payload: { constraints, features } }) => {
      state.changesToFieldsWithTermConstraints = true
      state.turbosquid_product_form.draftSavePendingChanges = true
      state.featureIds = features.map((f) => f.id)
      state.constraints = constraints
      if (features.length > 0) {
        state.turbosquid_product_form.formValidationErrors = deleteError(state.turbosquid_product_form.formValidationErrors, 'featureIds')
      } else if (state.publishValidationTriggered && features.length <= 0) {
        state.turbosquid_product_form.errors = manageErrors(state.turbosquid_product_form, [
          {
            messageForNotif: I18n.t('one_category_required_notif', { scope: 'turbosquid.products.product_specifications_panel' }),
            messageForInline: I18n.t('one_category_required', { scope: 'turbosquid.products.product_specifications_panel' }),
          },
        ], 'featureIds')
      }
    },
    updateCertification: (state, { payload: { certifications } }) => {
      state.turbosquid_product_form.formValidationErrors = {}
      state.displayAlerts = false
      state.fileErrors = {
        mainFiles: [],
        previews: [],
        associatedFiles: [],
      }

      if (certifications === 'stemcell_v2') {
        state.turbosquid_product_form.values.stemcell = certifications
        state.turbosquid_product_form.draftSavePendingChanges = true
      } else if (certifications === 'none') {
        state.turbosquid_product_form.values.stemcell = null
      }
    },
    updateLicense: (state, { payload: { license, focusInput } }) => {
      if (state.license !== license) {
        state.license = license
        state.turbosquid_product_form.draftSavePendingChanges = true
      }
      state.turbosquid_product_form.formValidationErrors = deleteError(state.turbosquid_product_form.formValidationErrors, 'license')
      state.focusBrandInput = Boolean(focusInput)
    },
    requestFailed: (state, _) => {
      state.formActionInProgress = undefined
      state.turbosquid_product_form.draftSaving = false
    },
    requestSucceeded: (state, { payload: { draftSaving, response } }) => {
      const { features, product } = response

      state.formActionInProgress = undefined
      if (draftSaving) {
        state.turbosquid_product_form.draftSaving = false
      }
      const updates = getDataFrom({ product, features })
      if (Object.keys(updates).length > 0) {
        state.createdAt = updates.createdAt
        state.draftId = updates.draftId
        state.license = updates.license
        state.status = updates.status
        state.updatedAt = updates.updatedAt

        if (updates.featureIds && updates.featureIds.length) {
          state.featureIds = updates.featureIds
        }
      }
    },
    constraintsFetchFailed: (state, { payload: { fieldName, fieldValue, errors } }) => {
      if (typeof fieldName !== 'undefined' && typeof fieldValue !== 'undefined') {
        state.turbosquid_product_form[fieldName] = fieldValue
        state.turbosquid_product_form.formValidationErrors = manageErrors(state.turbosquid_product_form, formValidationErrors, fieldName)
        state.turbosquid_product_form.touched = true
      }
    },
    saveDraftId: (state, { payload: { draftId } }) => {
      state.draftId = draftId
      if (Number(gon.product.id) <= 0 && state.draftId) {
        window.history.replaceState({}, '', `/turbosquid/drafts/${draftId}/edit`)
      }
    },
    publishProductInvalid: (state, { payload: { action, validationType } }) => {
      state.previewsErrorArray = []
      state.displayAlerts = true

      // File validation is now handled by hooks

      if (validationType !== 'preview') {
        // Used to check if the release validation has been triggered.
        state.publishValidationTriggered = true
      }

      state.formActionInProgress = action
    },
    saveValidationErrors: (state, { payload: { errors } }) => {
      state.turbosquid_product_form.formValidationErrors = errors
    },
    previewUpdateFields: (state, { payload: { errors, fields, id } }) => {
      const itemIndex = Object.keys(state.previews).findIndex((key) => state.previews[key].id === id)
      if (state.previews[itemIndex]) {
        const item = state.previews[itemIndex]

        fields.forEach((element) => {
          const { fieldName, fieldValue } = element
          item[fieldName] = fieldValue
        })

        state.previews[itemIndex].errors = errors
        state.turbosquid_product_form.draftSavePendingChanges = true
      }
    },
    discardDraft: (state, _) => {
      state.hadDraft = false
    },
    saveOldCertifiedPreviewsCertification: (state, { payload: { value } }) => {
      state.turbosquid_product_form.values.oldCertifiedPreviews = value
    },
    saveCertifiedPreviewsCertification: (state, { payload: { certifiedPreviews } }) => {
      state.turbosquid_product_form.values.certifiedPreviews = certifiedPreviews
    },
    disableCertifiedPreviewChange: (state, { payload: { value } }) => {
      state.disableCertifiedPreviewChange = value
    },
    setStemcell: (state, { payload: { stemcell } }) => {
      state.turbosquid_product_form.values.stemcell = stemcell
    },
    updateLastActionDraftSaving: (state, { payload }) => {
      state.lastActionDraftSaving = payload
    },
    updateFormActionInProgress: (state, { payload: { action } }) => {
      state.formActionInProgress = action
    },
    cancelStemcellSubmission: (state, { payload: { status, success } }) => {
      state.pendingCancelStemcellSubmission = status
      state.cancelStemcellSubmissionSuccess = success

      if (success) {
        state.certifications = []
      }
    },
    setCollectionFeature: (state, _) => {
      state.turbosquid_product_form.draftSavePendingChanges = true
    },
    updateBrandFeatures: (state, _) => {
      state.turbosquid_product_form.draftSavePendingChanges = true
    },
    setDataValidationState: (state, { payload: { status = true } }) => {
      state.dataValidationPassed = { status }
    },
    saveDraft: (state, _) => {
      state.turbosquid_product_form.draftSavePendingChanges = false
    },
    saveDraftDone: (state, _) => {},
    cancelStemcellSubmissionStart: (state, action) => {},
    fetchConstraints: (state, action) => {},
    fetchInitialConstraints: (state, action) => {},
    previewDraft: (state, action) => {},
    fetchFileCredentials: (state, action) => {},
    fetchFileInfo: (state, action) => {},
    disableDraftPendingChanges: (state, action) => {},
    previewUpdateOrderStart: (state, action) => {},
    retryFile: (state, action) => {},
    saveFeatureGraph: (state, action) => {},
    undoDeleteFile: (state, action) => {},
    savePreviewOrderDone: (state, _) => {
      state.draftSavePendingSort = false
    },
    setInvalidPreviewCount: (state, { payload: { status } }) => {
      state.invalidPreviewCount = status
    },
    setCreatingDraft: (state, { payload: { status } }) => {
      state.creatingDraft = status
    },
    setSkipDiffPreviews: (state, { payload: { status } }) => {
      state.skipDiffPreviews = status
    },
    setDisableDragSort: (state, { payload: { disabled } }) => {
      state.disableDragSort = disabled
    },
    setDraftSavePendingChanges: (state, { payload: { status } }) => {
      state.turbosquid_product_form.draftSavePendingChanges = status
    },
    addAsyncQueue: (state, { payload: { pattern = 'ACTION', task = { id: null } } }) => {
      if (!state.asyncQueue[pattern]) {
        state.asyncQueue[pattern] = []
      }
      state.asyncQueue[pattern].push(task)
    },
    removeAsyncQueueItem: (state, { payload: { id, pattern = 'ACTION' } }) => {
      if (state.asyncQueue[pattern] && id) {
        state.asyncQueue[pattern] = state.asyncQueue[pattern].filter((q) => q.id !== id)
      }
    },
    setDisableLeavePagePrompt: (state, { payload: { disable = false } }) => {
      state.disableLeavePagePrompt = disable
    },
  },
  extraReducers: {
    [dismissNotification]: (state, _) => {
      state.dataValidationPassed = { status: true }
      state.displayAlerts = false
    },
  },
})
