import React from 'react'
import { Provider } from 'react-redux'
import { publisherStore } from './react_components/publisher_store'
import PublisherFormConnected from './react_components/connected/publisher_form_connected'

const PublisherApplication = () => (
  <Provider store={publisherStore()}>
    <PublisherFormConnected />
  </Provider>
)

export default PublisherApplication
