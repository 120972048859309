/* global gon */
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs'

export const editDraftUrl = (productId) => {
  const params = {
    intType: 6,
    intFileSystemId: productId,
    stgAction: 'getProduct',
    intProductId: productId,
  }

  return `${gon.asset_manager_url}Index.cfm?${qs.stringify(params)}`
}

export const draftEditPageUrl = (id) => `${gon.turbosquid_drafts_path}/${id}/edit`
